.wrapper {
    color: #fff;
    padding: 16px;
    font-size: 12px;
}

.error {
    color: #ff8686;
}
.disconnected {
    color: #cfcfcf;
}
.connected {
    color: #88ff86;
}


@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;