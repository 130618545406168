.content {
	padding: 24px;
	// max-width: 600px;
}

.leftButtons {
	float: left;
}

.rightButtons {
	float: right;
	width: auto;
}

.storeSelect {
	width: 300px;
}

.regularText {
	font-weight: normal;
	font-size: 12px;
}

.chart {
	margin-top: 8px;
}

.cards {
	margin-bottom: 24px;
}

.card {
	height: 100%;
	width: 200px;

	:global {
		img {
			margin: 24px 24px 0 24px;
			width: 152px;
		}

		.ant-rate-star {
			font-size: 12px;
		}
	}
}

.slide {
	width: 100%;
	aspect-ratio: 1200/628;

	:global {
		img {
			width: 100%;
		}
	}
}

.carousel {
	margin-bottom: 24px;
}

.columnLink {
	padding: 0px !important;
	border: 0;
	height: auto;
}

.tableCard {
	:global {
		.ant-card-body {
			padding: 0;

			.ant-table-container {
				margin: 0 -1px -1px 0;
				border: 0;
			}
		}
	}
}

.image {
	height: 24px;
	position: absolute;
	top: 7px;
}

.carouselItem {
	padding: 8px;

	:global {
		img {
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
			width: 100%;
		}
	}
}

.carousel {
	margin-bottom: 24px;
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;