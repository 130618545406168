div.extraHeader {
	background-color: #fff;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 8px 16px;
	// border-bottom: 1px solid @border-color-base;
	width: 100%;
	height: 49px;
	margin-top: -1px;
	line-height: 1;
	box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;