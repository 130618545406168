.drop-over-downward {
	:global {
		td {
			border-bottom: 1px dashed #7e89c8 !important;
		}
	}
}

.drop-over-upward {
	:global {
		td {
			border-top: 1px dashed #7e89c8 !important;
			padding-top: 7px !important;
		}
	}
}

.formItem {
	margin-bottom: 0 !important;
}

.container {
	border: 1px solid #e9e9e9 !important;
	// padding: 8px;

	:global {
		.ant-empty {
			background-color: #fcfcfc;
			padding: 16px 0;
			margin: 0;
		}
	}
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;