:root {
	--inset-top: 0;
}


body {
	display: flex;
	flex: auto;
	flex-direction: column;
	overflow-x: hidden;
	letter-spacing: -0.04rem;
	overscroll-behavior-y: none;
}

input,
* {
	letter-spacing: -0.04rem;
}

/* Adjust Layout background */
.ant-layout {
	background: #fcfcfc !important;
}

/* Reduce Logo Padding */
.ant-pro-sider-logo {
	padding: 8px 16px;
}

/* Adjust dark sider color */
.ant-layout-sider-dark {
	background-color: #1e1e1e;
}

.ant-menu.ant-menu-dark {
	background-color: #1f1f1f !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
	background-color: #0f0f0f;
}

/* Adjust color of empty section */
.ant-table-empty .ant-table-tbody>tr.ant-table-placeholder {
	color: rgba(0, 0, 0, 0.85);
}

/* Remove collapse button */
.ant-menu-item.ant-pro-sider-collapsed-button {
	display: none !important;
}

/* Adjust nested content style */
.ant-layout .ant-pro-basicLayout.ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
	height: calc(100vh - 48px) !important;
}

/* Adjust modal title style to match header */
.ant-modal-title {
	display: block;
	width: 100%;
	color: rgba(0, 0, 0, 0.85) !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 16px !important;
}

/* Adjust th style to be bold */
.ant-table-thead>tr>th {
	font-weight: bold;
}

/* Adjust transfer table first column padding */
.ant-transfer .ant-table tr>*:first-child {
	padding-left: 8px !important;
}

/* Adjust pagination on pages */
.ant-pro-basicLayout-content .ant-table-pagination-right {
	padding-right: 24px;
}

/* Force message zIndex */
.ant-message {
	z-index: 99999;
}

/* Adjust Dropdown zIndex */
.ant-select-dropdown {
	z-index: 3000;
}

/* Adjust Dropdown zIndex */
.ant-select-dropdown,
.ant-picker-dropdown {
	z-index: 3000;
}

/* Disable scroll on empty tables */
.ant-table-empty .ant-table-body {
	overflow: hidden !important;
}

/* Adjust logo on collapsed sider */
.ant-pro-sider-collapsed .sider-logo span {
	display: none;
}

.ibuddy .ant-pro-sider-collapsed .sider-logo img:first-child {
	display: none !important;
}

.ibuddy .ant-pro-sider-logo .sider-logo img:first-child {
	margin-left: 22px !important
}

.ibuddy .ant-pro-sider-collapsed .sider-logo img:last-child {
	display: inline-block !important;
}

/* Adjust tags in table */
.ant-table.ant-table-small .ant-table-tbody td.ant-table-cell .ant-tag {
	height: 21px;
}

/* Grayscale empty image */
.ant-empty-image img {
	filter: grayscale(100%);
	opacity: 0.8;
}

.ant-pro-sider.ant-layout-sider-collapsed .ant-pro-sider-logo {
	padding: 8px 8px !important;
}

/* Adjust description headers */
.ant-descriptions-header {
	margin-bottom: 10px !important;
}

/* Adjust card paddings on small screens */
@media (max-width: 767px) {
	* {
		letter-spacing: -0.06rem;
	}

	.ant-card-body {
		padding: 16px !important;
	}

	.ant-card-head {
		padding: 0 16px !important;
		min-height: 42px !important;

		.ant-card-head-title {
			padding: 8px 0 !important;
		}

		.ant-card-extra {
			padding: 8px 0 !important;
		}
	}

	.ant-drawer-body {
		padding: 16px !important;
	}

	.ant-drawer-header {
		padding: 16px !important;
	}

	.noPadding {
		.ant-drawer-body {
			padding: 0px !important;
		}
	}

	.extra-header {
		display: none !important;
	}

	.has-mobile-header .ant-pro-global-header {
		box-shadow: none;
		-webkit-box-shadow: none;
	}

	.has-mobile-header {
		.ant-layout-header:first-child {
			height: 96px !important;
		}
	}

	// .ant-modal {
	// 	max-width: 100vw !important;
	// 	margin: 0px !important;

	// }
}

//dark mode
@media (prefers-color-scheme: dark) {
	.ant-pro-global-header {
		border-bottom: 1px solid @border-color-split;
	}
}

@media (min-width: 768px) {
	.mobile-header {
		display: none !important;
	}

	.noPadding {
		.ant-drawer-body {
			padding: 0px !important;
		}
	}
}

/* Max drawer width */
.ant-drawer-content-wrapper {
	max-width: 100vw !important;
}

.ant-pro-sider-collapsed .sider-logo img {
	height: 26px !important;
}

.ibuddy .ant-pro-sider-collapsed .sider-logo img {
	margin-left: 2px !important;
}

.ant-layout-sider-collapsed img {
	margin-left: 4px !important;
}

.ant-menu-inline-collapsed-tooltip .ant-pro-menu-item img {
	display: none;
}

.ant-menu-submenu-popup .ant-menu-item img {
	width: 24px !important;
	vertical-align: middle;
}

.ant-pro-global-header-logo {
	display: none;
}

.ant-pro-global-header-collapsed-button {
	margin-left: 0 !important;
	margin-right: 16px;
}

.ant-layout .ant-pro-basicLayout.ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar .ant-layout {
	height: calc(100vh - 48px) !important;
	overflow: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
span.ant-input-affix-wrapper>input.ant-input:-webkit-autofill:focus,
input:-webkit-autofill::first-line {
	box-shadow: 0 0 0 30px white inset !important;
	font-family: 'Montserrat';
	font-size: 14px;
}

#root {
	display: flex;
	flex: 1;
	flex-direction: column;
}

#root>div {
	display: flex;
	flex: 1;
}

.ant-drawer-header-title {
	display: block !important;
	width: 100%;
}

.ant-drawer-header-title button {
	float: right;
	margin: 0;
	padding: 0;
}

.ant-drawer-header .ant-drawer-title {
	color: rgba(0, 0, 0, 0.85) !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 16px !important;
}

.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.App-header {
	background-color: #222;
	height: 150px;
	padding: 20px;
	color: white;
}

.App-intro {
	font-size: large;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.posTabs.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
	height: auto;
}

.ant-layout-header h2 {
	line-height: 32px;
}

.ant-table-filter-dropdown {
	min-width: 200px !important;
}

.bo-filters {
	padding: 16px 24px;
	border-top: 1px solid #dfdfdf;
	background: #efefef;
}

.bo-select-loading {
	text-align: center;
}

.bo-due-past {
	color: #ff0000;
}

.bo-paid {
	color: darkgreen;
}

.bo-not-paid {
	color: #ff0000;
}

.ant-page-header-title-view-extra .ant-btn {
	margin-top: -7px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.bo-top-border td {
	border-top: 1px solid #e9e9e9;
}

.bo-calculation {
	border-left: 1px solid #e8e8e8;
}

.bo-calculation-summary tr td:first-child,
.bo-pricechange-summary tr td:first-child {
	background: none !important;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.85);
	text-align: right;
}

.bo-calculation-signature-print,
.bo-calculation-info-print,
.bo-pricechange-signature-print,
.bo-pricechange-info-print {
	display: none;
}

iframe[id^='printElement_'] {
	width: 100%;
	height: 100%;

	position: relative !important;
}

.posTotal {
	font-family: 'Roboto Mono', monospace;
}

.ant-table tr>*:first-child {
	padding-left: 16px !important;
}

.ant-table tr>*:last-child {
	padding-right: 16px !important;
}

.ant-empty-image {
	height: 128px;
}

@media print {
	* {
		font-size: 9px;
		color: black;
	}

	.ant-table tr>*:first-child {
		padding: 4px 3px !important;
	}

	.ant-table tr>*:last-child {
		padding: 4px 3px !important;
	}

	table th {
		white-space: nowrap;
		border-right: 1px solid #000 !important;
		border-bottom: 1px solid #000 !important;
		background: #aaa !important;
	}

	table td {
		white-space: normal;
		border-right: 1px solid #000 !important;
		border-bottom: 1px solid #000 !important;
	}

	table th,
	table td {
		padding: 4px 3px !important;
	}

	table {
		border-top: 1px solid #000 !important;
		border-left: 1px solid #000 !important;
		margin-top: 15px;
	}

	h2 {
		font-size: 12px;
		padding: 15px 0 0 0;
	}

	table col {
		width: auto !important;
		min-width: auto !important;
	}

	.ant-table-expanded-row {
		width: 100%;
	}

	.bo-calculation-info-print,
	.bo-calculation-signature-print,
	.bo-pricechange-info-print,
	.bo-pricechange-signature-print {
		display: block;
	}

	.bo-calculation-signature-print,
	.bo-pricechange-signature-print {
		margin-top: 30px;
	}

	.bo-calculation .ant-table-footer,
	.bo-pricechange .ant-table-footer {
		display: none;
	}

	.bo-calculation .bo-column-actions,
	.bo-pricechange .bo-column-actions {
		display: none;
	}

	.bo-calculation-summary-container>div,
	.bo-pricechange-summary-container>div {
		float: none;
	}

	.bo-calculation-summary-col1 {
		width: 50%;
	}

	.bo-calculation-summary-col2 {
		width: 100%;
	}

	.bo-calculation {
		border-left: none;
	}

	.ant-table-wrapper {
		border-bottom: none;
	}
}

.ant-pro-sider .ant-menu .anticon {
	color: rgba(0, 0, 0, 1) !important;
	/* padding-top: 4px; */
}

.ant-pro-sider .ant-menu img {
	width: 24px;
	vertical-align: middle;
}

.ant-result-icon .anticon {
	color: rgba(0, 0, 0, 1) !important;
}

.ant-empty-image .anticon svg {
	color: rgba(0, 0, 0, 1) !important;
	width: 100px;
	height: 100px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
	box-shadow: darken(@primary-color, 20%) 3px 0px 0px inset !important;
	background-color: rgba(183, 137, 188, 0.3);
}

.ant-menu-dark.ant-menu-inline .ant-menu-item {
	box-shadow: rgba(183, 137, 188, 0) 3px 0px 0px inset;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
		box-shadow 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-layout-content .ant-layout-sider .ant-pro-sider-logo {
	padding: 0 16px;
	line-height: 48px;
}

#thermal-temporary {
	font-smooth: antialiased;
	-webkit-font-smoothing: antialiased;
	/* font-weight: 200; */
}

.Resizer {
	background: #000;
	opacity: 0.2;
	z-index: 1;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

.Resizer:hover {
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.Resizer.horizontal {
	height: 0;
	margin: 0 0 -5px 0;
	border-bottom: 5px solid rgba(255, 255, 255, 0);
	border-top: none;
	cursor: row-resize;
	width: 100%;
}

.Resizer.horizontal:hover {
	border-bottom: 5px solid rgba(0, 0, 0, .2);
	// border-bottom: 5px solid rgba(0, 0, 0, .2);
}

.Resizer.vertical {
	width: 0;
	margin: 0 0 0 -6px;
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: none;
	cursor: col-resize;
}

.Resizer.vertical:hover {
	border-left: 5px solid rgba(0, 0, 0, .2);
	/* border-right: 5px solid rgba(0, 0, 0, .2); */
}

.Resizer.disabled {
	cursor: not-allowed;
}

.Resizer.disabled:hover {
	border-color: transparent;
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;