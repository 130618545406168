.wrapper {
	height: 44px;
	line-height: 44px;

	a {
		text-decoration: none;
	}

	display: block;
}


.iBuddyLogo {
	height: 40px !important;
}

.iBuddyLogoSmall {
	display: none !important;
}


.logo {
	height: 40px !important;
	margin-right: 4px;
	vertical-align: top !important;
	// -webkit-filter: invert(100%) drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.3));
	// filter: invert(100%) drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.3));
	// -webkit-filter: invert(100%);
	// filter: invert(100%);
	display: inline !important;
	margin-top: -1px;
	transition: all 0.2s ease-in-out;
}

.logo:hover {
	transform: scale(1.5);
}

.title {
	line-height: 40px;
	position: relative;
	color: #fff;
	font-weight: 600;
	font-size: 32px;
	margin-left: 8px;
	letter-spacing: -0.06em;
	// text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
	// text-decoration: underline;
}

.dark {
	:global {
		span {
			color: rgba(0, 0, 0, 0.85) !important;
		}

		img {
			filter: invert(0);
		}
	}
}

.version {
	font-weight: 400;
	color: #fff;
	font-size: 14px;
	font-variant: normal;
	padding-left: 8px;
}

.small {
	width: 180px;
	height: 24px;
	line-height: 24px !important;

	.logo {
		height: 24px !important;
		margin-right: 2px;
		margin-top: 0px;
	}

	.iBuddyLogo {
		height: 32px !important;
	}

	.title {
		line-height: 24px !important;
		font-size: 20px;
	}
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;