.addButton {
    padding: 0;
}

.container {
    border: 1px solid @border-color-split !important;

    :global {
        .ant-empty {
            background-color: #fcfcfc;
            padding: 16px 0;
            margin: 0;
        }
    }
}

:global {
    .ant-form-item-has-error {
        :local {
            .container {
                border-color: @error-color !important;
            }
        }
    }
}

.table {
    margin-bottom: -1px;

    :global {
        .ant-table-tbody>tr.ant-table-row>td {
            padding: 4px 4px !important;
        }

        .ant-table-tbody>tr.ant-table-row>*:first-child {
            padding-left: 8px !important;
        }

        .ant-table-tbody>tr.ant-table-row>*:last-child {
            padding-right: 8px !important;
        }

    }
}

.item {
    padding: 16px 16px 0 16px;
    border-bottom: 1px solid @border-color-split !important;

    .buttons {
        text-align: right;
        margin-bottom: 16px;
    }
}

.item:last-child {
    border-bottom: 0 !important;

    .buttons {
        margin-bottom: 8px;
    }
}

.footer {
    padding: 8px 8px 0 8px;
    border-top: 1px solid @border-color-split !important;
    background-color: #fcfcfc;
}

.editableCell {
    vertical-align: top;
}

.nonEditableCell {
    padding: 8px 4px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;