.container {
	width: 100%;
}
.table {
	width: 100%;
	:global {
		td,
		th {
			vertical-align: top !important;
		}
		img {
			height: 16px;
			vertical-align: middle;
			margin-right: 8px;
		}
	}
}

.innerTable {
	:global {
		.ant-table tr > th.ant-table-cell,
		.ant-table tr > td.ant-table-cell {
			vertical-align: top !important;
			padding-right: 0 !important;
		}
	}
}

@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;