.electron {
	:global {
		.ant-layout-sider-children {
			padding-top: 24px;
		}

		.ant-layout-content .ant-layout-sider-children {
			padding-top: 0px;
		}

		.ant-layout-sider-children::after {
			position: absolute;
			height: 24px;
			-webkit-app-region: drag;
			width: 200px;
			top: 0;
			left: 0;
			content: ' ';
		}


	}
}

:global {
	:local(.electron) {

		&.screen-md,
		&.screen-sm,
		&.screen-xs {
			padding-top: 28px !important;

			.ant-pro-fixed-header {
				top: 28px !important;

				&::before {
					width: 100%;
					height: 28px;
					content: ' ';
					position: fixed;
					top: 0;
					left: 0;
					background-color: #1f1f1f;
					-webkit-app-region: drag;
				}
			}


		}
	}

	.electron-mac {
		@media (max-width: 991px) {
			.ant-drawer-content {
				margin-top: 28px;
				height: calc(100% - 28px) !important;
			}
		}
	}
}

.capacitor {
	:global {
		.ant-layout-sider-children::before {
			display: block;
			content: " ";
			height: var(--inset-top);
			background-color: #fff;
			width: 100%;
		}

		.ant-layout>.ant-layout-header::before {
			display: block;
			content: " ";
			height: var(--inset-top);
			background-color: #fff;
			width: 100%;
		}
	}
}

.layout {
	:global {
		.ant-layout-sider-collapsed {
			.connection-status-wrapper {
				font-size: 16px;

				span.status-text {
					display: none;
				}
			}
		}
	}
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;