.rightAlign {
	text-align: right;
}

.image {
	height: 32px;
	position: absolute;
	top: 4px;
}

.skeleton {
	height: 14px;
	display: inline-block;

	:global {
		.ant-skeleton-content .ant-skeleton-paragraph {
			margin: 0;
		}
	}
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;