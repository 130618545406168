div.header {
	display: inline-block;
	width: calc(100% - 48px);
	-webkit-app-region: drag;
	text-overflow: ellipsis;
	overflow: hidden;
	height: 48px;
	white-space: nowrap;
}

div.title {
	display: inline;

	color: rgba(0, 0, 0, 0.85);
	font-weight: bold;
	font-size: 16px;
}

div.subtitle {
	display: inline;

	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 16px;
}

div.icon {
	float: left;
	margin-right: 8px;

	:global {
		img {
			width: 32px;
			vertical-align: middle;
		}
	}
}

div.extraHeader {
	/* line-height: 1.4; */
	float: right;
	/* vertical-align: middle; */
	display: flex;
	align-items: center;
	justify-content: center;
	height: 49px;
	line-height: 1;
	// box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;