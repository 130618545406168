.image {
    height: 24px;
    position: absolute;
    top: 7px;
}

.warning {
    color: orange;
    position: absolute;
    right: 8px;
    top: 12px;
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;