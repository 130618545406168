.table {
    margin: 16px -24px -17px -72px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);

    :global {
        * {
            font-size: 0.8rem;
        }

        th,
        td {
            padding: 4px 8px !important;
        }

        .ant-table-measure-row td {
            padding: 0 !important;
        }
    }

}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;