.noReceipts {
    color: #d9d9d9;
}

.datePicker {
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
}

.calendar {
    :global {
        th {
            padding: 0 8px 5px 0 !important;
        }

        .ant-picker-calendar-date {
            margin: 0 !important;
            border-top-width: 1px !important;

            .ant-picker-calendar-date-content {
                height: calc(((100vh - 48px - 8px - 23px) / 6) - 31px) !important;
            }

            &.ant-picker-calendar-date-today {
                border-top-width: 2px !important;
            }


        }

        .ant-picker-cell-selected {
            .ant-picker-calendar-date {
                background-color: unset !important;
            }
        }
    }
}

.list {
    :global {
        .ant-list-item {
            padding: 8px 16px !important;

            .ant-list-item-meta-avatar {
                width: 180px;
                text-align: right;
            }

            .ant-btn {
                padding: 0 !important;
                line-height: 1 !important;
                height: auto !important;
            }
        }
    }
}

.listButton {
    text-align: left;
}

:global(.screen-xs) .calendar {
    :global {


        .ant-picker-calendar-date {
            // padding: 4px 4px 0;

            .ant-badge-status-text {
                display: none;
            }

            .ant-picker-calendar-date-content {
                height: calc((100vh - 48px - 49px) / 6 - 24px - 13px)
            }
        }
    }
}

:global(.screen-sm) .calendar {
    :global {
        .ant-picker-calendar-date {
            // padding: 4px 4px 0;

            .ant-badge-status-text {
                display: none;
            }

            .ant-picker-calendar-date-content {
                height: calc((100vh - 48px - 49px) / 6 - 24px - 13px);
                min-height: 30px;
            }
        }
    }
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;