.connectedProductWrapper {
    padding-left: 116px;
}

.filter {
    margin: -16px -16px 32px -16px;
    padding: 16px;
}

:global {
    .ant-affix {
        :local {
            .filter {
                background-color: #fff;
                border-bottom: 1px solid @border-color-split;
                padding: 16px;
                margin: -16px -16px 32px -16px;
            }
        }
    }
}
@hack: ''; @import "/root/ibuddy-app/src/assets/styles/index.less";@primary-color: #f49c11;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;